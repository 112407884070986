<template>
  <div>
    <div>
      <div class="text-h6">
        Sympathische, ansprechende und überall akzeptierte (biometrische)
        Passbilder!
        <br />
      </div>
      <div class="text-body-1">
        <br />
        Da die meisten Kunden keine <b>vier</b> biometrische Passbilder
        benötigen, können Sie bei uns wählen, ob Sie <b>vier</b> biometrische,
        oder <b>zwei</b> biometrische und <b>zwei</b> klassische Bilder bekommen
        möchten.
        <br />
      </div>
      <div style="margin-top: 2rem">
        <v-row justify="center">
          <v-col>
            <v-row justify="center">
              <v-img
                max-width="200px"
                contain
                src="../../../../public/img/passbilder/Passbild4erBio.jpg"
              ></v-img>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify="center">
              <v-img
                max-width="200px"
                contain
                src="../../../../public/img/passbilder/Passbild2undGross.jpg"
              ></v-img>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
      <div class="text-body-1">
        <div class="international">
          <div>
            <div><v-icon x-large>mdi-earth</v-icon></div>
          </div>
          <div>
            <div>Auch für <b>jedes</b> internationale Visum!</div>
          </div>
        </div>
      </div>
      <div class="text-body-1">
        <div class="international">
          <div>
            <div><v-icon x-large>mdi-calendar</v-icon></div>
          </div>
          <div>
            <div><b>Kein</b> Termin notwendig!</div>
          </div>
        </div>
      </div>

      <br />

      <v-alert
        class="bad"
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
      >
        Wir nehmen schlechte biometrische Passbilder von Fahrschulen oder
        Schreibwarengeschäfte usw. für<br />
        <span class="text-h6"><b>2.50 Euro</b></span><br />
        in Zahlung!
      </v-alert>

      <div class="request">
        <contact-now title="Jetzt Passbilder machen!"></contact-now>
      </div>
    </div>
  </div>
</template>

<script>
import ContactNow from "../../../components/nav/ContactNow";

export default {
  components: { ContactNow },
};
</script>

<style scoped>
.international {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;
}
.bad {
  text-align: center;
  margin: 1rem 0 0 1rem;
}
</style>